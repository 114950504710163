<template>
  <v-dialog persistent width="800px" height="600px" v-model="dialogProxy">
    <v-card class="px-2 py-2">
      <v-card-title class="H4-Secondary-Center">Add Global Menu</v-card-title>
      <v-layout class="ml-3 mr-3">
        <v-flex xs12>
          <v-text-field
            label="Search Brands"
            single-line
            solo
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          />
        </v-flex>
      </v-layout>
      <div>
        <v-list class="Companies-List my-2 ml-3 mr-3" id="companies-list">
          <v-list-tile
            v-for="(c, cIndex) in orderBy(filterBy(companies, search, 'name'), 'name')"
            class="Company-Cell px-2 Body-1-Selected-On-Surface-High-Emphasis-Left"
            v-bind:key="cIndex"
            @click="() => {}"
          >
            <v-list-tile-action>
              <v-checkbox
                :label="c.name"
                v-model="selectedCompaniesCache[c.id]"
                :value="true"
                v-if="isVisible(cIndex)"
              />
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="blue" v-on:click="cancel()" v-show="!loading">CANCEL</v-btn>
        <v-btn flat color="blue" v-on:click="confirm()" :loading="loading">ADD</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import filters from 'vue2-filters';
import { mapActions } from 'vuex';

export default {
  name: 'AddCompaniesDialog',
  mixins: [filters.mixin],
  props: {
    dialog: {
      default: false,
    },
    companies: {
      default: [],
    },
    sector: Object,
  },
  data: () => ({
    loading: false,
    dialogProxy: false,
    selectedCompaniesCache: {},
    search: null,
    visibleAreaTop: 0,
    visibleAreaBottom: 550,
    companiesListDOMNode: null,
    saveData: false,
    companyNodeHeight: 48,
    listHeight: 450,
    offset: 450,
  }),
  async mounted() {
    try {
      this.companiesListDOMNode = document.getElementById('companies-list');
      this.companiesListDOMNode.addEventListener('scroll', this.handleScroll);
    } catch (err) {
      console.error(err);
    }
  },
  beforeDestroy() {
    const listPanel = document.getElementById('companies-list');
    if (listPanel) listPanel.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    dialogProxy(d) {
      if (!this.dialogProxy) {
        this.$set(this, 'selectedCompaniesCache', {});
        this.$set(this, 'search', null);
      }
      if (d === this.dialog) return;
      this.$emit('update:dialog', d);
    },
    dialog(d) {
      if (this.dialogProxy === d) return;

      this.$set(this, 'dialogProxy', d);
    },
  },
  computed: {
    selectedCompanies() {
      try {
        return Object.keys(this.selectedCompaniesCache).filter(
          (c) => this.selectedCompaniesCache[c],
        );
      } catch {
        return [];
      }
    },
  },
  methods: {
    ...mapActions('menus', ['postMenu']),
    async confirm() {
      if (this.selectedCompanies.length > 0) {
        try {
          this.loading = true;
          await Promise.all(
            this.selectedCompanies.map((company) =>
              this.postMenu({ company, sector: this.sector.id }),
            ),
          );
          this.$emit('addedCompany');
          this.loading = false;
          this.dialogProxy = false;
          this.$toast.success('Global menu(s) successfully created');
        } catch (err) {
          this.$toast('Could not add the selected brands');
        }
      } else {
        this.$toast('You must select at least one brand');
      }
    },
    async cancel() {
      this.dialogProxy = false;
    },
    handleScroll() {
      this.visibleAreaTop = this.companiesListDOMNode.scrollTop - this.offset;
      this.visibleAreaBottom = this.visibleAreaTop + this.listHeight + this.offset;
    },
    isVisible(i) {
      return (
        (i + 1) * this.companyNodeHeight >= this.visibleAreaTop &&
        (i + 1) * this.companyNodeHeight <= this.visibleAreaBottom
      );
    },
  },
};
</script>

<style>
.Companies-List {
  height: 325px;
  overflow: scroll;
  border: solid 1px #757575;
}
.Company-Cell {
  width: 100%;
  height: 48px;
}
</style>
