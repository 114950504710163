import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      !_vm.loading
        ? _c(
            VLayout,
            [
              _c(
                VFlex,
                { staticStyle: { "padding-left": "0px" }, attrs: { xs8: "" } },
                [
                  _c("view-title", {
                    attrs: {
                      search: true,
                      dropDownItems: _vm.all_sectors,
                      activeItem: _vm.active_sector,
                      label: "Search Groups"
                    },
                    on: { primaryItemChanged: _vm.goToNewSector }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticClass: "mt-3",
                  staticStyle: { position: "relative" },
                  attrs: { xs4: "" }
                },
                [
                  _c(
                    VLayout,
                    { staticClass: "mr-2", attrs: { "justify-end": "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                        [
                          _c(
                            VBtn,
                            {
                              staticStyle: { "border-radius": "8px" },
                              attrs: {
                                disabled:
                                  _vm.companiesWithoutGlobalMenu.length === 0,
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.dialog = true
                                }
                              }
                            },
                            [
                              _c(VIcon, { attrs: { left: "", dark: "" } }, [
                                _vm._v("mdi-plus")
                              ]),
                              _vm._v("ADD GLOBAL MENU\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      staticClass: "svg-videocam",
                                      attrs: { outline: "" },
                                      on: { click: _vm.toggleDialog }
                                    },
                                    on
                                  ),
                                  [_c(VIcon, [_vm._v("mdi-help-circle")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4259306028
                      )
                    },
                    [_c("span", [_vm._v("Training Video")])]
                  ),
                  _c("video-player", {
                    attrs: { dialog: _vm.videoDialog, videoID: _vm.videoID },
                    on: {
                      "update:dialog": function($event) {
                        _vm.videoDialog = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "loading-wrapper",
        { attrs: { loading: _vm.loadingMenus, mt: "20vh", size: 80 } },
        [
          _c(
            VLayout,
            { staticStyle: { width: "660px" } },
            [
              _c(VTextField, {
                attrs: {
                  label: "Search Global Menus",
                  "single-line": "",
                  solo: "",
                  "prepend-inner-icon": "mdi-magnify",
                  clearable: "",
                  value: _vm.search
                },
                on: { input: _vm.updateSearchValue }
              })
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VCard,
                { staticClass: "v-card-custom" },
                [
                  _c(
                    VLayout,
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        {
                          staticClass: "Subtitle-1-Secondary-Left",
                          attrs: { xs12: "" }
                        },
                        [_vm._v("Global Menus")]
                      ),
                      _vm.companiesWithGlobalMenuOnPage.length === 0
                        ? _c(VFlex, { attrs: { xs12: "" } }, [
                            _c("p", [_vm._v("No menu found")])
                          ])
                        : _vm._l(_vm.companiesWithGlobalMenuOnPage, function(
                            company,
                            index
                          ) {
                            return _c("menu-list-item", {
                              key: company.id,
                              attrs: {
                                search: _vm.search,
                                record: company,
                                globalMenuList: true,
                                index: index
                              }
                            })
                          }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-xs-center flex xs12 display-flex justify-center"
                        },
                        [
                          _c(VPagination, {
                            attrs: {
                              "total-visible": 10,
                              length: _vm.numberOfPages,
                              "prev-icon": "mdi-menu-left",
                              "next-icon": "mdi-menu-right"
                            },
                            model: {
                              value: _vm.page,
                              callback: function($$v) {
                                _vm.page = $$v
                              },
                              expression: "page"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("add-companies-dialog", {
        attrs: {
          dialog: _vm.dialog,
          companies: _vm.companiesWithoutGlobalMenu,
          sector: _vm.active_sector
        },
        on: {
          "update:dialog": function($event) {
            _vm.dialog = $event
          },
          addedCompany: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }