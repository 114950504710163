<template>
  <v-container grid-list-xl>
    <v-layout v-if="!loading">
      <v-flex xs8 style="padding-left: 0px;">
        <view-title
          :search="true"
          :dropDownItems="all_sectors"
          :activeItem="active_sector"
          @primaryItemChanged="goToNewSector"
          label="Search Groups"
        />
      </v-flex>
      <v-flex xs4 class="mt-3" style="position: relative;">
        <v-layout justify-end class="mr-2">
          <div class="FAB-Extended-PrimaryColor-Enabled">
            <v-btn
              :disabled="companiesWithoutGlobalMenu.length === 0"
              color="primary"
              @click="dialog = true"
              style="border-radius: 8px;"
            >
              <v-icon left dark>mdi-plus</v-icon>ADD GLOBAL MENU
            </v-btn>
          </div>
        </v-layout>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn outline class="svg-videocam" v-on="on" @click="toggleDialog">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Training Video</span>
        </v-tooltip>
        <video-player :dialog.sync="videoDialog" :videoID="videoID" />
      </v-flex>
    </v-layout>
    <loading-wrapper :loading="loadingMenus" mt="20vh" :size="80">
      <v-layout style="width: 660px;">
        <v-text-field
          label="Search Global Menus"
          single-line
          solo
          prepend-inner-icon="mdi-magnify"
          clearable
          :value="search"
          @input="updateSearchValue"
        />
      </v-layout>
      <v-layout>
        <v-card class="v-card-custom">
          <v-layout row wrap>
            <v-flex xs12 class="Subtitle-1-Secondary-Left">Global Menus</v-flex>
            <v-flex xs12 v-if="companiesWithGlobalMenuOnPage.length === 0"
              ><p>No menu found</p></v-flex
            >
            <menu-list-item
              v-else
              :search="search"
              v-for="(company, index) of companiesWithGlobalMenuOnPage"
              :record="company"
              :key="company.id"
              :globalMenuList="true"
              :index="index"
            />
            <div class="text-xs-center flex xs12 display-flex justify-center">
              <v-pagination
                v-model="page"
                :total-visible="10"
                :length="numberOfPages"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </v-layout>
        </v-card>
      </v-layout>
    </loading-wrapper>

    <add-companies-dialog
      :dialog.sync="dialog"
      :companies="companiesWithoutGlobalMenu"
      :sector="active_sector"
      @addedCompany="init"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import filters from 'vue2-filters';
import videoPlayer from '@/components/videoPlayerModal';
import { VimeoVideoID } from '@/constants';
import debounce from 'lodash/debounce';
import AddCompaniesDialog from './addCompaniesDialog';
import menuListItem from './menuListItem';

export default {
  name: 'SectorInfo',
  components: {
    AddCompaniesDialog,
    menuListItem,
    videoPlayer,
  },
  mixins: [filters.mixin],
  data: () => ({
    search: '',
    sort: 'asc',
    expansionPanelValues: {},
    globalMenusCache: {},
    dialog: false,
    videoDialog: false,
    sectorMenusFetched: false,
    page: 1,
    itemsPerPage: 10,
  }),
  computed: {
    ...mapState('sectors', ['active_sector', 'all_sectors']),
    ...mapState('menus', ['sectorsFetchedMap']),
    ...mapState('adminPanel', ['loading']),
    ...mapGetters('menus', ['getSectorMenus']),
    numberOfPages() {
      return Math.ceil(this.companiesWithGlobalMenu.length / this.itemsPerPage);
    },
    companiesWithGlobalMenuOnPage() {
      const _from = (this.page - 1) * this.itemsPerPage;
      const _to = this.page * this.itemsPerPage;
      return this.companiesWithGlobalMenu.slice(_from, _to);
    },
    loadingMenus() {
      const sectorLoaded = !!(
        this.active_sector && this.active_sector.id === this.$route.params.sector_id
      );
      return !sectorLoaded || !this.sectorMenusFetched;
    },
    sectorMenus() {
      if (!this.active_sector) return [];
      return this.getSectorMenus(this.active_sector.id);
    },
    videoID() {
      return VimeoVideoID.globalMenu;
    },
    companiesWithoutGlobalMenu() {
      if (!this.active_sector) return [];
      const menusSectorCompanySet = new Set();
      this.sectorMenus.forEach((menu) => {
        menusSectorCompanySet.add(menu.company);
      });
      const nonGlobal = [];

      // determine which companys have menus
      this.active_sector.companies.forEach((company) => {
        if (!menusSectorCompanySet.has(company.id)) {
          nonGlobal.push(company);
        }
      });

      return nonGlobal;
    },
    companiesWithGlobalMenu() {
      if (
        !this.active_sector ||
        this.active_sector.id !== this.$route.params.sector_id ||
        !this.sectorMenus ||
        !this.sectorMenus.length
      ) {
        return [];
      }
      const menusSectorCompanySet = new Set();
      this.sectorMenus.forEach((menu) => {
        menusSectorCompanySet.add(menu.company);
      });
      let global = [];

      // determine which companys have menus
      this.active_sector.companies.forEach((company) => {
        if (menusSectorCompanySet.has(company.id)) {
          global.push(company);
        }
      });
      if (this.search && this.search.length > 0) {
        const searchValue = (this.search || '').toLowerCase();
        global = global.filter((company) => `${company.name}`.toLowerCase().includes(searchValue));
      }
      return global;
    },
  },
  mounted() {
    this.$store.dispatch('sectors/fetchSectors');
    this.init();
  },
  methods: {
    ...mapActions('menus', ['fetchSectorMenu']),
    ...mapActions('users', ['fetchUser']),
    toggleDialog() {
      this.videoDialog = !this.videoDialog;
    },
    goToNewSector(sector) {
      if (this.$route.params.sector_id !== sector.id) {
        this.$router.push({
          name: 'menu-sector-info',
          params: {
            sector_id: sector.id,
          },
        });
      }
    },
    async init() {
      this.$store.commit('adminPanel/setLoading', true);
      this.sectorMenusFetched = false;
      const sectorId = this.$route.params.sector_id;
      const loadCachedMenusFirst = this.sectorsFetchedMap[sectorId];
      await this.fetchSectorMenu({
        id: sectorId,
        nocache: loadCachedMenusFirst ? undefined : true,
      });
      if (loadCachedMenusFirst) {
        // we first allow cached menus to show up for the user, while update will be done not affecting UX
        this.fetchSectorMenu({
          id: sectorId,
          nocache: true,
        });
      }

      this.$store.commit('adminPanel/setLoading', false);
      this.sectorMenusFetched = true;
    },
    // eslint-disable-next-line
    updateSearchValue: debounce(function(value) {
      this.search = value;
      this.page = 1;
    }, 350),
  },
};
</script>
<style scoped>
.svg-videocam {
  width: 30px;
  height: 30px;
  display: inline;
  border: none !important;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
<style>
.Menu-List-Sector {
  width: 100%;
}
.Company-Row:hover {
  color: #0d73d8;
}
.Brand-Container-Sector:hover {
  background: #eff8ff;
  color: #0d73d8;
}
.Brand-Container-Sector {
  margin-left: 42px;
  cursor: pointer;
}
</style>
