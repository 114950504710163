import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", width: "800px", height: "600px" },
      model: {
        value: _vm.dialogProxy,
        callback: function($$v) {
          _vm.dialogProxy = $$v
        },
        expression: "dialogProxy"
      }
    },
    [
      _c(
        VCard,
        { staticClass: "px-2 py-2" },
        [
          _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
            _vm._v("Add Global Menu")
          ]),
          _c(
            VLayout,
            { staticClass: "ml-3 mr-3" },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Search Brands",
                      "single-line": "",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify"
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                VList,
                {
                  staticClass: "Companies-List my-2 ml-3 mr-3",
                  attrs: { id: "companies-list" }
                },
                _vm._l(
                  _vm.orderBy(
                    _vm.filterBy(_vm.companies, _vm.search, "name"),
                    "name"
                  ),
                  function(c, cIndex) {
                    return _c(
                      VListTile,
                      {
                        key: cIndex,
                        staticClass:
                          "Company-Cell px-2 Body-1-Selected-On-Surface-High-Emphasis-Left",
                        on: { click: function() {} }
                      },
                      [
                        _c(
                          VListTileAction,
                          [
                            _vm.isVisible(cIndex)
                              ? _c(VCheckbox, {
                                  attrs: { label: c.name, value: true },
                                  model: {
                                    value: _vm.selectedCompaniesCache[c.id],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectedCompaniesCache,
                                        c.id,
                                        $$v
                                      )
                                    },
                                    expression: "selectedCompaniesCache[c.id]"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                ),
                1
              )
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading"
                    }
                  ],
                  attrs: { flat: "", color: "blue" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("CANCEL")]
              ),
              _c(
                VBtn,
                {
                  attrs: { flat: "", color: "blue", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                [_vm._v("ADD")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }